import moment from "moment-timezone";
import "moment/locale/id";
import "moment/locale/en-au";

moment.locale('id');

export const setDefaultLocale = () => {
  if (typeof window !== "undefined") {
    const searchParams = new URLSearchParams(window.location.search);
    const lang = searchParams.get("lang");
    moment.locale(lang === 'en' ? 'en-au' : "id");
  }
};

export default moment;
